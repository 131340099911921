.login_admin {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #23242a;
}


.login_admin .box {
    position: relative;
    width: 20rem;
    height: 20rem;
    background: #1c1c1c;
    border-radius: 18px;
    overflow: hidden;
}

.login_admin .box::before {
    content: "";
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: -50%;
    left: -50%;
    background: linear-gradient(0deg, transparent, transparent, #45f3ff, #45f3ff, #45f3ff);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

.login_admin .box::after {
    content: "";
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: -50%;
    left: -50%;
    background: linear-gradient(0deg, transparent, transparent, #ffff00, #ffff00, #ffff00);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

.login_admin .borderLine {
    position: absolute;
    top: 0;
    inset: 0;
}

.login_admin .borderLine::before {
    content: "";
    position: absolute;
    width: 400px;
    height: 470px;
    top: -50%;
    left: -50%;
    background: linear-gradient(0deg,
            transparent,
            transparent,
            #fc0f8d,
            #fc0f8d,
            #fc0f8d);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.login_admin .box form {
    position: absolute;
    inset: 4px;
    background: #222;
    padding: 50px 40px;
    border-radius: 18px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.login_admin .box form h2 {
    color: #fff;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1em;
}

.login_admin .box form .inputBox {
    position: relative;
    width: 100%;
    margin-top: 35px;
}

.login_admin .box form .inputBox input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
}

.login_admin .box form .inputBox span {
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.login_admin .box form .inputBox input:valid~span,
.login_admin .box form .inputBox input:focus~span {
    color: #fff;
    font-size: 0.75em;
    transform: translateY(-34px);
}

.login_admin .box form .inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.login_admin .box form .inputBox input:valid~i,
.login_admin .box form .inputBox input:focus~i {
    height: 44px;
}


.login_admin .box form input[type="submit"] {
    border: none;
    outline: none;
    padding: 9px 25px;
    background: #fff;
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 7px;
    font-weight: 600;
    width: 100px;
    margin-top: 10px;
    transition: 0.6s;
}

.login_admin .box form input[type="submit"]:hover {
    background-color: #31e1f7;
}

.login_admin .box form input[type="submit"]:active {
    opacity: 0.8;
}