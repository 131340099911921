/* ///// inputs /////*/

.contact_us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    justify-content: space-evenly;
}

.contact_us h2 {
    background: #111;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    width: max-content;
    border-radius: 5px;
}

.contact_us .full_contact_us {
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 1rem;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 1rem;
    margin: auto;
}

.contact_us_left {
    display: flex;
    gap: 1rem;
    width: 90%;
    padding: 1rem;
    flex-direction: column;
}


form.contact_us_left div {
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

form.contact_us_left div div {
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-wrap: wrap;
}

form.contact_us_left div .label_input {
    display: flex;
    gap: 0;
}

.contact_us label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.contact_us input[type="text"],
.contact_us input[type="email"],
.contact_us input[type="number"],
.contact_us select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #ccc;
}

.contact_us input::placeholder {
    color: #ccc;
}


.contact_us .label_input_checkbox {
    display: flex !important;
    gap: 5px !important;
}


.contact_us input[type="submit"] {
    background: #111;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
}

.contact_us input[type="submit"]:hover {
    background-color: #1111117c;
}

.contact_us .contact_us_right {
    display: flex;
    gap: 1rem;
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    background: #111;
    border-radius: 1rem;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.contact_us .contact_us_right::after {
    content: "";
    position: absolute;
    bottom: -50%;
    right: -50%;
    width: 20rem;
    height: 20rem;
    background: orange;
    border-radius: 50%;
}

.contact_us .contact_us_right div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    z-index: 99;
}

.contact_us .contact_us_right .icons {
    flex-direction: row;
}

.contact_us .contact_us_right .icons svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.contact_us .contact_us_right .icons svg:hover {
    transform: scale(1.1);
}

.contact_us .contact_us_right div b {
    display: flex;
    align-items: center;
    gap: 5px;
}

.contact_us .contact_us_right div b svg {
    width: 1.5rem;
    height: 1.5rem;
}


.contact_us select option {
    color: #111;
}

.contact_us select option:nth-child(1) {
    display: none;
}

.contact_us .contact_us_right .icons a {
    color: #fff;
}

@media (max-width: 768px) {
    .contact_us .full_contact_us {
        grid-template-columns: 1fr;
    }

    form.contact_us_left div {
        grid-template-columns: 1fr;
    }
}