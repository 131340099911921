.copyrights {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
    background: #111;
    color: #fff;
    font-weight: bold;
}

.copyrights {
    padding: 1rem 0;
}

.copyrights a {
    color: #ffa500;
}

@media (max-width: 1023px) {
    .copyrights {
        font-size: 12px;
    }
}