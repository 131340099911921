       .add_post .upload_form {
           display: flex;
           align-items: center;
           justify-content: center;
       }

       .add_post .upload_form form {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           gap: 2rem;
           width: 90%;
           margin: 1rem auto;
           border-radius: 1rem;
           padding: 1rem;
           box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
           overflow: hidden;
       }

       .add_post .upload_form form .div_input {
           display: flex;
           align-items: center;
           justify-content: center;
           width: 100%;
           gap: 1rem;
       }

       .add_post .upload_form form div.price {
           position: relative;
           width: 100%;
       }

       .add_post .upload_form form div.price span {
           position: absolute;
           top: 50%;
           right: 5px;
           transform: translateY(-50%);
       }

       .add_post .upload_form .images {
           display: flex;
           align-items: center;
           justify-content: center;
           width: 10rem;
           height: 10rem;
           position: relative;
           background: #111;
           cursor: pointer;
       }

       .add_post .images.logo_about {
           width: 5rem;
           height: 5rem;
           background: #fff;
       }

       .add_post .upload_form .images img {
           width: 10rem;
           height: 10rem;
       }

       .add_post .upload_form .images.logo_about img {
           width: 5rem;
           height: 5rem;
       }

       .add_post .upload_form input#file_post {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           opacity: 0;
           cursor: pointer;
       }

       .add_post .upload_form form input,
       .add_post .upload_form form select {
           width: 100%;
           padding: 0.5rem;
           border: 1px solid #ccc;
           border-radius: 5px;
       }

       .add_post .upload_form input[type="submit"] {
           background-color: #111;
           color: white;
           border: none;
           padding: 10px 20px;
           cursor: pointer;
           border-radius: 5px;
           width: fit-content;
       }

       .all_postes {
           width: 90%;
           display: flex;
           flex-wrap: wrap;
           justify-content: center;
           gap: 2rem;
           margin: 2rem auto;
       }

       .all_postes .poste {
           display: flex;
           flex-direction: column;
           gap: 5px;
           border: 1px solid #111;
           border-right: none;
           border-top: none;
           padding: 1rem;
           border-radius: 5px;
           position: relative;
           transition: all 0.5s ease-in-out 0s;
       }

       .all_postes .poste:hover {
           transform: scale(1.05);
           border-radius: 1rem;
       }

       span.delete_poste {
           background: transparent;
           color: #f00;
           font-size: 2rem;
           position: absolute;
           top: -1rem;
           right: 0;
           cursor: pointer;
       }

       .all_postes div img {
           width: 10rem;
           height: 10rem;
           object-fit: cover;
           object-position: center;
       }

       .all_postes div p {
           font-weight: bold;
           color: #111;
           text-transform: uppercase;
       }

       .div_load_more {
           width: 100%;
           display: flex;
           align-items: center;
           justify-content: center;
           margin: 2rem auto;
       }

       button.load_more {
           background-color: #111;
           color: white;
           border: none;
           padding: 10px 20px;
           cursor: pointer;
           border-radius: 4px;
           font-weight: bold;
           font-size: 1.1rem;
           transition: all 0.5s ease-in-out 0s;
       }

       .all_postes .poste div p:last-child {
           font-weight: initial;
           color: #111;
           text-transform: none;
       }

       a.add-post {
           display: block;
           color: #fff;
           text-align: center;
           padding: 14px 16px;
           background: #111;
           width: fit-content;
           margin: auto;
       }

       @media (max-width: 1023px) {
           .add_post .upload_form form div {
               flex-wrap: wrap;
           }

           .all_postes {
               width: 100%;
               gap: 0;
           }
       }