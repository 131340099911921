.all_postes {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 2rem auto;
}

.all_postes .poste {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
}

.all_postes .poste:hover {
    transform: scale(1.05);
    border-radius: 1rem;
}


.all_postes div img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    object-position: center;
}

.all_postes div p {
    font-weight: bold;
    color: #111;
    text-transform: uppercase;
}

.div_load_more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
}

button.load_more {
    background-color: #111;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.1rem;
    transition: all 0.5s ease-in-out 0s;
}

.all_postes .poste div p:last-child {
    font-weight: initial;
    color: #111;
    text-transform: none;
}

.view_img_div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111111d4;
    padding: 2rem;
    animation: view_img_div 0.5s ease-in-out;
}

@keyframes view_img_div {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.view_img_div img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.view_img_div span {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.view_img_div span svg {
    position: fixed;
    top: 10px;
    right: 10px;
}

.view_img_div span svg {
    color: #fff;
    font-size: 2rem;
}

@media (max-width: 1023px) {

    .all_postes {
        width: 100%;
        gap: 0;
    }
}