.view_ContactUs {
    padding: 1rem;
}

.view_ContactUs table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.view_ContactUs thead {
    background-color: #f2f2f2;
}

.view_ContactUs th,
.view_ContactUs td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    white-space: nowrap;
}

.view_ContactUs tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.view_ContactUs tbody tr:hover {
    background-color: #e5e5e5;
}

/* ///////////////// */
form.contact_us_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 20rem;
    margin: 1rem auto;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

form.contact_us_data input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

form.contact_us_data input[type="submit"] {
    background-color: #111;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}