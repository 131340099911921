.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    margin: 2rem auto;
    width: fit-content;
    height: 100%;
    min-height: 90vh;
}

.gallery .div_category {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    width: 15rem;
}

.gallery .parent-container {
    display: grid;
    width: 100%;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}


.gallery .parent-container img {
    transition: all 0.5s ease-in-out 0s;
    width: 15rem;
    height: 15rem;
    object-fit: cover;
    object-position: center;
}

.gallery .parent-container img:hover {
    transform: scale(1.05);
}

.gallery h2 {
    background: #111;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    width: max-content;
    border-radius: 5px;
}

.gallery .div_category p {
    font-weight: bold;
    color: #111;
    cursor: pointer;
    text-transform: uppercase;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}


@media (max-width: 768px) {
    .gallery .parent-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .gallery .div_category {
        width: 10rem;
    }

    .gallery .parent-container img {
        width: 9rem;
        height: 9rem;
    }
}