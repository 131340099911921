.bgimg-1 {
    height: 60vh;
    position: relative;
    margin-bottom: 5rem;
}

.bgimg-1 img {
    height: 60vh;
    object-fit: cover;
}

.caption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0000;
}

.bgimg-1 .info {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}



.caption span.border {
    background: #111;
    color: #fff;
    height: 4rem;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: 5px;
}

@media (max-width:1023px) {
    .caption span.border {
        padding: 10px;
        font-size: 1rem;
        white-space: nowrap;
        height: 3rem;
    }

    .bgimg-1 .info {
        bottom: -1.5rem;
    }
}