.about-me .container {
    width: 100%;
    margin: 0px auto 0px auto;
    padding: 0px 40px;
}



.about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    margin: 2rem auto;
    width: 100%;
    height: 100%;
    min-height: 90vh;
}

.about-me .about-me-container {
    position: relative;
}


.about-me .about-me-title {
    background: #111;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    width: max-content;
    border-radius: 5px;
}


.about-me-flex-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.about-me-flex-container .about-me-image {
    width: 25rem;
    height: 25rem;
    margin: auto;
}

.about-me-flex-container .about-me-image .main-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.about-me-flex-container .about-me-image .main-image img {
    transform-origin: center center;
    -o-object-fit: cover;
    object-fit: cover;
}

.about-me-flex-container .about-me-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    flex: 0 0 40%;
    padding: 1rem 0;
}


.about-me-flex-container .about-me-content .logo {
    max-width: 20rem;
    max-height: 20rem;
    overflow: hidden;
}


.about-me-flex-container .about-me-content .text {
    color: #111;
    font-size: 18px;
}


@media (max-width: 1023px) {
    .about-me-flex-container .about-me-content .logo {
        max-width: 10rem;
        max-height: 10rem;
    }

    .about-me-flex-container .about-me-content .text {
        font-size: 16px;
    }

    .about-me .container {
        padding: 0px 30px;
    }

    .about-me-flex-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        gap: 50px;
        margin-top: -10px;
    }

    .about-me-flex-container .about-me-image {
        width: 20rem;
        height: 20rem;
    }

    .about-me-flex-container {
        grid-template-columns: 1fr;
    }
}