.formUploadLogo {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 10rem;
    margin: 1rem auto;
}

.fileUploadLogo {
    position: relative;
    width: 7rem;
    height: 7rem;
    overflow: hidden;
    margin: auto;
    background: #111;
}

.formUploadLogo .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.formUploadLogo .previewImage {
    max-width: 100%;
    height: auto;
}

.formUploadLogo button {
    margin-top: 20px;
    background-color: #2ecc71;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.formUploadLogo button:hover {
    background-color: #27ae60;
}

.formUploadLogo button:disabled {
    opacity: 0.3;
}

/* imagesSlider */
.imagesSlider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.imageSlider {
    width: 10rem;
}

.imageSlider img {
    height: 10rem;
    object-fit: cover;
}

.imageSlider button {
    background: transparent;
    font-size: 2rem;
    color: #f00;
    cursor: pointer;
}

hr {
    border: 2px solid #333;
    width: 100%;
    margin: 1rem auto;
}