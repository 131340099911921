.featured_collections {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    margin: 2rem auto;
    width: 100%;
    height: 100%;
    min-height: 90vh;
}

.featured_collections h2 {
    background: #111;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    width: max-content;
    border-radius: 5px;
}

.featured_collections .mySlides {
    display: none
}

.featured_collections img {
    vertical-align: middle;
}

.featured_collections .slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
}

.featured_collections .slideshow-container img {
    height: 75vh;
    object-fit: cover;
}

.prev_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    direction: ltr;
}

.featured_collections .prev,
.featured_collections .next {
    cursor: pointer;
    padding: 16px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background: #111;
}

.featured_collections .next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.featured_collections .prev:hover,
.featured_collections .next:hover {
    background-color: #11111177;
}

.featured_collections .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}




.featured_collections .fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 300px) {

    .featured_collections .prev,
    .featured_collections .next {
        font-size: 11px
    }
}

@media (max-width: 1023px) {

    .featured_collections .slideshow-container img {
        height: 50vh;
    }
}