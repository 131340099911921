       .categories .upload_form {
           display: flex;
           align-items: center;
           justify-content: center;
           flex-direction: column;
           gap: 1rem;
       }

       .categories .upload_form form {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           gap: 2rem;
           width: 20rem;
           margin: 1rem auto;
           border-radius: 1rem;
           padding: 1rem;
           box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
           overflow: hidden;
       }

       .categories .upload_form .images {
           display: flex;
           align-items: center;
           justify-content: center;
           width: 10rem;
           height: 10rem;
           position: relative;
           background: #111;
           cursor: pointer;
       }

       .categories .upload_form .images img {
           width: 10rem;
           height: 10rem;
       }

       .categories .upload_form input#file_Categories {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           opacity: 0;
           cursor: pointer;
       }

       .categories .upload_form input[type="text"] {
           width: 100%;
           padding: 0.5rem;
           border: 1px solid #ccc;
           border-radius: 5px;
       }

       .categories .upload_form input[type="submit"] {
           background-color: #111;
           color: white;
           border: none;
           padding: 10px 20px;
           cursor: pointer;
           border-radius: 4px;
       }

       .view_category {
           width: 100%;
           display: flex;
           justify-content: center;
           flex-wrap: wrap;
           gap: 2rem;
       }

       .categories .div_category {
           display: flex;
           flex-direction: column;
           align-items: center;
           gap: 5px;
           border: 1px solid #111;
           border-right: none;
           border-top: none;
           padding: 1rem;
           border-radius: 5px;
           position: relative;
       }

       .categories .div_category span.delete_category {
           background: transparent;
           color: #f00;
           font-size: 2rem;
           position: absolute;
           top: -1rem;
           right: 0;
           cursor: pointer;
       }


       .categories .div_category img {
           transition: all 0.5s ease-in-out 0s;
           width: 10rem;
           height: 10rem;
           border-radius: 0.5rem;
           object-fit: cover;
           object-position: center;
       }

       .categories .div_category:hover {
           transform: scale(1.05);
           border-radius: 1rem;
       }

       .categories .div_category p {
           font-weight: bold;
           color: #111;
           text-transform: uppercase;
       }