.header_admin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_admin button {
    display: block;
    color: #fff;
    text-align: center;
    padding: 15px 16px;
    background: orange;
    cursor: pointer;
}

.header_admin ul {
    overflow: hidden;
    background: #333;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.header_admin li a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 14px 16px;
}

.header_admin li a:hover:not(.active) {
    background: #111;
}

.header_admin ul li:first-child a {
    background: transparent;
}

.header_admin li a.active {
    background: #111;
}