        .upload_form {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .upload_form form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            width: 20rem;
            margin: 1rem auto;
            border-radius: 1rem;
            padding: 1rem;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            overflow: hidden;
        }

        .upload_form .images {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10rem;
            height: 10rem;
            position: relative;
            background: #111;
            cursor: pointer;
        }

        .images.logo_about {
            width: 5rem;
            height: 5rem;
            background: #fff;
        }

        .upload_form .images img {
            width: 10rem;
            height: 10rem;
        }

        .upload_form .images.logo_about img {
            width: 5rem;
            height: 5rem;
        }

        .upload_form input#file_upload {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        .upload_form form textarea {
            width: 100%;
            min-height: 10rem;
            border: 1px solid #ccc;
            padding: 5px;
            border-radius: 5px;
            resize: none;
        }

        .upload_form input[type="submit"] {
            background-color: #111;
            color: white;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            border-radius: 4px;
        }